import { Button, Input } from "@mui/material";
import React, { ReactNode, useState } from "react";
import { PageContainer } from "../../components/PageContainer";
import { useMutation, useQuery } from "@apollo/client";
import { GET_CONFIG, UPDATE_CONFIG } from "../config/graphql";
import { Config } from "../../__generated__/graphql";
import { MapUpload } from "./mapUpload";
import { toast } from "react-toastify";
interface ISelectProps {
  onCompletePress: (file: string, fileName: string) => void;
}

export const Map = () => {
  const { data, loading, error } = useQuery(GET_CONFIG);
  const configs = (data?.configs as Config[]) || undefined;

  const mapUrl = configs?.find((item) => item.key === "MAP_IMAGE_URL")?.value;

  const [image, setImage] = useState<string>();
  const [file, setFile] = useState<string>();
  const [uploadReady, setUploadReady] = useState(false);
  const [fileName, setFileName] = useState<string>();
  const [updateConfig] = useMutation(UPDATE_CONFIG, {
    awaitRefetchQueries: true,
    refetchQueries: ["config"],
    onCompleted: () => {
      toast("Map Url Updated");
    },
  });
  const onFileChange = async (event: any) => {
    try {
      const file = event.target.files[0];

      const split = file.name.split(".");
      const ext = split[split.length - 1];
      setFileName(`map/map.${ext}`);

      const obj = URL.createObjectURL(event.target.files[0]);
      setImage(obj);
      setFile(file);
    } catch (err) {
      console.log(err);
    }
  };
  const handleClick = () => {
    if (image && fileName) {
      setUploadReady(true);
    }
  };

  const returnAction = (url: string) => {
    updateConfig({
      variables: {
        settings: {
          key: "MAP_IMAGE_URL",
          value: url,
        },
      },
    });
  };

  if (uploadReady) {
    return (
      <MapUpload
        file={image!}
        fileName={fileName!}
        returnAction={returnAction}
      />
    );
  }

  return (
    <PageContainer title="Select an image">
      {mapUrl && <img src={mapUrl} height={500} />}
      <EditImageContainer>
        <Input type="file" onChange={onFileChange} />
        <Button
          disabled={!file}
          onClick={handleClick}
          variant="contained"
          style={{ alignSelf: "flex-end" }}
        >
          Proceed
        </Button>
      </EditImageContainer>
      <img src={image} alt="" width={"75%"} />
    </PageContainer>
  );
};

type PropsWithChildren<P> = P & { children?: ReactNode };

export const EditImageContainer: React.FunctionComponent<
  PropsWithChildren<{}>
> = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
        paddingBottom: 46,
      }}
    >
      {children}
    </div>
  );
};
