import { useMutation } from "@apollo/client";
import { DELETE_BAND, UPDATE_BAND } from "../graphql";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  Button,
  Container,
  TextField,
  Checkbox,
  FormLabel,
} from "@mui/material";
import { type Crop } from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import { Band } from "../../../__generated__/graphql";
import { toast } from "react-toastify";
import { confirmAlert } from "react-confirm-alert";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { PageContainer } from "../../../components/PageContainer";
import { ButtonContainer } from "../../../components/ButtonContainer";
import { Info } from "../../../components/Info";
import BandPerformances from "../performances";
import { Label } from "@mui/icons-material";
const schema = yup
  .object({
    name: yup.string().required(),
    description: yup.string().required(),
    showcase: yup.boolean(),
  })
  .required();

const BandForm = ({ band }: { band: Band }) => {
  const navigate = useNavigate();
  const [updateBand, { loading: updateLoading, error }] = useMutation(
    UPDATE_BAND,
    {
      onCompleted: () => {
        toast("band updated");
      },
    }
  );
  const [deleteBand] = useMutation(DELETE_BAND, {
    onCompleted: () => {
      toast("band deleted");
      navigate("/bands");
    },
  });
  const { id } = useParams();
  const [image, setImage] = useState<string>();
  // const [crop, setCrop] = useState<Crop>();
  const [crop, setCrop] = useState<Crop>({
    unit: "%", // Can be 'px' or '%'
    x: 25,
    y: 25,
    width: 50,
    height: 50,
  });

  const {
    register,
    handleSubmit,
    watch,
    control,
    formState: { errors },
  } = useForm({ resolver: yupResolver(schema) });
  const handleDelete = () => {
    confirmAlert({
      title: "Delete this Band?",
      message: "Are you sure? this can't be undone",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteBand({ variables: { bandId: band.id } }),
        },
        {
          label: "No",
          onClick: () => {},
        },
      ],
    });
  };

  const [imageUrl, setImageUrl] = useState(band.imageUrl ?? "");

  const location = useLocation();

  useEffect(() => {
    console.log("STATE HAS CHANGED!", location.state);
    if (location?.state?.updatedImageUrl) {
      console.log("updating image!");
      setImageUrl(location?.state?.updatedImageUrl);
    }
  }, [location.state]);

  const onSubmit = (val: any) => {
    const { name, description, showcase } = val;
    updateBand({
      variables: {
        id: band.id,
        name,
        description,
        imageUrl: imageUrl,
        showcase,
      },
    });
  };
  // aspect ratio 3∶2

  const imageKey = location?.state?.updated ?? "imageUrl";

  return (
    <PageContainer title={`Edit Band: ${band.name}`}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ flexDirection: "column", display: "flex" }}>
          <img
            src={imageUrl}
            width={500}
            style={{ paddingBottom: 8 }}
            key={imageKey}
          />
          <Button
            variant="contained"
            style={{ marginBottom: 18 }}
            onClick={() => {
              navigate("/image", {
                state: {
                  bandId: band.id,
                },
              });
            }}
          >
            Update Image
          </Button>
          <TextField
            id="outlined-basic"
            label="Name"
            variant="outlined"
            defaultValue={band?.name}
            {...register("name")}
          />
          <p>{errors.name?.message}</p>
          <TextField
            id="outlined-basic"
            label="Description"
            variant="outlined"
            multiline={true}
            defaultValue={band?.description}
            {...register("description")}
          />
          <p>{errors.description?.message}</p>

          <Controller
            control={control}
            name="showcase"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <>
                <FormLabel>Showcase</FormLabel>
                <Checkbox
                  id="outlined-basic"
                  {...register("showcase")}
                  defaultChecked={band.showcase}
                  value={value}
                />
              </>
            )}
          />
          <p>{errors.showcase?.message}</p>
          <ButtonContainer>
            <Button
              type="submit"
              variant="contained"
              style={{ marginTop: 18, marginBottom: 18 }}
            >
              Save
            </Button>
            <Button
              variant="contained"
              onClick={handleDelete}
              style={{ backgroundColor: "red" }}
            >
              Delete band
            </Button>
          </ButtonContainer>
        </Container>
      </form>
      <Info />
      <div>
        <BandPerformances bookings={band.bookings} />
      </div>
    </PageContainer>
  );
};

export default BandForm;
