/* eslint-disable */
import * as types from './graphql';
import { TypedDocumentNode as DocumentNode } from '@graphql-typed-document-node/core';

/**
 * Map of all GraphQL operations in the project.
 *
 * This map has several performance disadvantages:
 * 1. It is not tree-shakeable, so it will include all operations in the project.
 * 2. It is not minifiable, so the string of a GraphQL query will be multiple times inside the bundle.
 * 3. It does not support dead code elimination, so it will add unused operations.
 *
 * Therefore it is highly recommended to use the babel or swc plugin for production.
 */
const documents = {
    "\nquery bandsForPicker($orgId: String!) {\n  bands(where: { organisationId: { equals: $orgId } }) {\n    id\n    name\n  }\n}\n": types.BandsForPickerDocument,
    "\nquery stages {\n  stages {\n    id\n    name\n  }\n}\n": types.StagesDocument,
    "\nquery band($id: String!) {\n  band (where: {id: $id}){\n    id\n    name\n    description\n    imageUrl\n    showcase\n    bookings {\n      id\n      edition {\n        id\n        name\n      }\n      startTime\n      endTime\n      stage {\n        name\n      }\n    }\n  }\n}\n": types.BandDocument,
    "\nmutation updateBand($id: String!, $name: String, $description: String, $imageUrl: String, $showcase: Boolean) {\n  updateBand(band: { id: $id, name: $name, description: $description, imageUrl: $imageUrl, showcase: $showcase }) {\n    id\n    name\n    description\n    imageUrl\n    showcase\n  }\n}\n": types.UpdateBandDocument,
    "\nmutation ceateBand($name: String!, $description:String!, $organisationId: String!) {\n  createBand(name: $name, description:$description,organisationId: $organisationId){\n    id\n  }\n}\n": types.CeateBandDocument,
    "\nmutation addImage($image: String!){\n  addImage(image: $image)\n}\n": types.AddImageDocument,
    "mutation deleteBand($bandId:String!) {\n  deleteBand(bandId: $bandId){\n    id\n  }\n}": types.DeleteBandDocument,
    "\nquery bands($take: Int!, $skip: Int!, $where: BandWhereInput) {\n  bandCount\n  bands(skip:$skip, take: $take, where: $where) {\n    id\n    name\n    description\n    bookings {\n      edition {\n        id\n        year\n      }\n      id\n      date\n      stage {\n        name\n      }\n    }\n  }\n}\n": types.BandsDocument,
    "\nquery bandBooking($id:String) { \n  bandBooking(where: {id: $id}){\n    id\n    edition {\n      id\n    }\n    band  {\n      id\n      name\n    }\n    stage {\n      id\n      name\n    }\n    date\n    duration\n    startTime\n    endTime  \n  }}\n": types.BandBookingDocument,
    "\nmutation updateBooking($id: String!, $startTime: String!, $endTime: String!, $date: String!, $stageId: String!) {\n  updateBandBooking(bandBooking: { id: $id, startTime:$startTime, endTime: $endTime, date: $date, stageId: $stageId})\n  {\n    id\n    band  {\n      id\n      name\n    }\n    date\n    duration\n    startTime\n    endTime \n    stage {\n      name\n    }\n  }\n}\n": types.UpdateBookingDocument,
    "\nmutation deleteBandBooking($bookingId: String!) {\n  deleteBandBooking(bookingId: $bookingId) {\n    id\n  }\n}\n": types.DeleteBandBookingDocument,
    "\nmutation createBooking($bandId: String!, $startTime: String!,$editionId: String!, $stageId: String!, $endTime: String!, $date: String!  ) {\n  createBandBooking(bandId: $bandId,\n                   startTime: $startTime,\n  editionId: $editionId,\n  stageId: $stageId,\n            endTime: $endTime,\n            date: $date) {\n    id\n                   }\n}\n": types.CreateBookingDocument,
    "\nquery config { \n    configs {\n        keyId: key\n        key\n        value\n  }\n  }": types.ConfigDocument,
    "\nmutation createConfig($settings: [ConfigInputType]!) { \n    updateConfig(settings: $settings)\n}\n": types.CreateConfigDocument,
    "\nmutation deleteConfig($key: String!) { \n    deleteConfig(key: $key) {\n        key\n    }\n}\n": types.DeleteConfigDocument,
    "\nquery drinks {\n  drinks {\n    id\n    drinkId:id\n    name\n    description\n    abv\n    manufacturer\n    type\n  }\n}\n": types.DrinksDocument,
    "mutation updateDrinks($drinks: [DrinkInputType!]!) {\n  updateDrinks(drinks: $drinks) {\n    id\n    drinkId:id\n    name\n    description\n    abv\n    manufacturer\n  }\n}": types.UpdateDrinksDocument,
    "mutation deleteDrink($drinkId: String!) {\n  deleteDrink(drinkId: $drinkId)\n}": types.DeleteDrinkDocument,
    "\n    mutation updateBookings($bandBookings: [BandBookingUpdateInputType]!) {\n      updateBandBookings(bandBookings: $bandBookings)\n      {\n        id\n        band  {\n          id\n          name\n        }\n        date\n        duration\n        startTime\n        endTime \n        stage {\n          name\n        }\n      }\n    }\n    ": types.UpdateBookingsDocument,
    "\n        query festivalEditionForEdit($id: String!) {\n          festivalEdition(where: {\n            id: $id\n          }) {\n            name\n            year\n            bands {\n              id\n              bookingId: id\n              showcase\n              band {\n                id\n                name\n                description\n                imageUrl\n                bookings {\n                  id\n                  startTime\n                  endTime\n                }\n              }\n              date\n              duration\n              stage {\n                id\n                name\n              }\n              startTime\n              endTime\n            }\n            days {\n              id\n              date\n              name\n            }\n          }\n        }\n        ": types.FestivalEditionForEditDocument,
    "\nmutation createEdition($name: String!, $year: String!, $orgId: String!) {\n  createEdition (festival: {\n    name: $name, year: $year, organisationId: $orgId\n  }){\n    id\n    name    \n  }\n}\n": types.CreateEditionDocument,
    "\nquery festivalSingalEdition($festivalId: String!) {\n  festivalEdition(where: {\n    id: $festivalId\n  }) {\n    id\n    name\n    year\n    availabilityDate\n  }\n}\n": types.FestivalSingalEditionDocument,
    "\nmutation updateEdition($id:String!, $name: String!, $year:String!, $availabilityDate: String!) {\n  updateEdition(festival: {\n    id: $id,\n    name: $name,\n    year: $year    \n    availabilityDate: $availabilityDate\n}){\n    id\n}\n}\n": types.UpdateEditionDocument,
    "mutation deleteEdition($editionId: String!) {\n  deleteEditionAndBands(editionId: $editionId)\n}": types.DeleteEditionDocument,
    "\nquery festivalEditions {\n    festivalEditions {\n        id\n        name\n      year\n      days {\n        id\n        date\n        name\n      }\n    }\n  }\n": types.FestivalEditionsDocument,
    "\nquery festivalEdition($id: String!) {\n  festivalEdition(where: {\n    id: $id\n  }) {\n    name\n    year\n    bands {\n      id\n      showcase\n      band {\n        id\n        name\n        description\n        imageUrl\n        bookings {\n          id\n          startTime\n          endTime\n        }\n      }\n      date\n      duration\n      stage {\n        name\n      }\n      startTime\n      endTime\n    }\n    days {\n      id\n      date\n      name\n    }\n  }\n}\n": types.FestivalEditionDocument,
    "query clashfinderExport($name: String!, $id:String!) {\n  clashfinderExport(name: $name, id:$id)\n}": types.ClashfinderExportDocument,
    "\nmutation addAssets($key: String!){\n  addAssets(key: $key)\n}\n": types.AddAssetsDocument,
    "\nmutation getExportMetaConfigUrl($key: String!){\n  getExportMetaConfigUrl(key: $key)\n}\n": types.GetExportMetaConfigUrlDocument,
    "\nquery info($id: String!) {\n  info(where: {\n    id: $id\n  }) {\n    id\n    infoId: id\n    title\n    value\n    order\n    hidden\n}\n}\n": types.InfoDocument,
    "\n    query infos {\n      infos {\n         id\n         infoId: id\n        title\n        value\n        order\n        hidden\n}\n    }\n    ": types.InfosDocument,
    "\n  mutation createInfo($title: String!, $value: JSON!) {\n  createInfo(\n    title: $title,\n    value: $value,\n  ) {\n    id\n    infoId: id\n    title\n    value\n    order\n    hidden\n    }\n}": types.CreateInfoDocument,
    "\n  mutation updateInfo($id: String!, $title: String!, $value: JSON!, $hidden: Boolean!, $order: Int!) {\n  updateInfo(info:{id: $id, title: $title, value: $value, hidden: $hidden, order:$order}) {\n    id  \n    title\n    value\n    order\n    hidden  \n  }  \n}    ": types.UpdateInfoDocument,
    "\n mutation deleteInfo($infoId: String!) {\n  deleteInfo(infoId: $infoId) {\n    id\n  }\n}": types.DeleteInfoDocument,
    "\nquery links {\n  links {\n    id\n    linkId:id\n    title\n    url\n    disabled\n    linkType\n    order\n  }\n}\n": types.LinksDocument,
    "mutation updateLink($links: [LinkInputType!]!) {\n    updateLinks(links:$links)\n  }": types.UpdateLinkDocument,
    "mutation deleteLink($linkId: String!) {\n  deleteLink(linkId: $linkId) {\n    id\n  }\n}": types.DeleteLinkDocument,
    "\nmutation signin($email: String!, $password: String!) {\n  signin(email: $email, password: $password) {\n    token\n  }\n}\n": types.SigninDocument,
    "\n    query getAppAndAsetConfigs {\n        appConfigs {\n            id\n            value\n        }\n        assetConfigs {\n            id\n            value\n        }\n    }\n    ": types.GetAppAndAsetConfigsDocument,
    "\nquery assetConfig($id: String!) {\n  assetConfig(where: { id: $id }) {\n    id\n    value\n  }\n}\n    ": types.AssetConfigDocument,
    "mutation createAppAndAssetConfig($id: String!) {\n    createAppAndAssetConfig(id: $id)\n}": types.CreateAppAndAssetConfigDocument,
    "mutation updateAppConfig($id: String!, $value: Json!) {\n    updateAppConfig(id: $id, value: $value){\n      id\n      value\n    }\n}\n": types.UpdateAppConfigDocument,
    "mutation updateAssetConfig($id: String!, $value: Json!) {\n    updateAssetConfig(id: $id, value: $value){\n      id\n      value\n    }\n}\n": types.UpdateAssetConfigDocument,
    "\nquery getAppAndAssetConfigs($id: String!) {\n    appConfig(where: { id: $id }) {\n        id\n        value\n      }\n    assetConfig(where: { id: $id }) {\n      id\n      value\n    }\n  }\n": types.GetAppAndAssetConfigsDocument,
    "\n  query getMetaAssetUrl($key: String!) {\n  getMetaAssetUrl(key: $key)\n}\n      ": types.GetMetaAssetUrlDocument,
    "mutation notification($title: String!, $message:String!, $exportNumber: String) {\n    sendNotification(title: $title, message:$message, exportNumber:$exportNumber)\n  }": types.NotificationDocument,
    "query notificationHistories {\n  notificationHistories(orderBy: {\n    date: desc\n  }) {\n    id\n    date\n    title\n    message\n    exportNumber\n  }\n}": types.NotificationHistoriesDocument,
    "\nquery post($id: String!) {\n  post (where: {id: $id}){\n    id\n    date\n    description\n    image\n    title\n    socials\n  }\n}\n": types.PostDocument,
    "\nmutation updatePost($id: String!, $title: String!, $description: String!, $date: String!, $image: String!, $socials: JSON) {\n  updatePost(post: { id: $id, title: $title, description: $description, date: $date, image:$image, socials: $socials }) {\n    id\n  }\n}\n": types.UpdatePostDocument,
    "\nmutation createPost($title: String!, $description: String!,$date: DateTime!, $image: String!, $socials: JSON) {\n  createPost(title: $title, description: $description, date: $date, image: $image, socials: $socials ) {\n    id\n  }\n}\n": types.CreatePostDocument,
    "mutation exportPost($postId: String!) {\n  exportPost(postId: $postId)\n}": types.ExportPostDocument,
    "mutation deletePost($postId:String!) {\n  deletePost(postId: $postId){\n    id\n  }\n}": types.DeletePostDocument,
    "\nquery news($take: Int!, $skip: Int!) {\n  posts(skip: $skip, take: $take, orderBy: { date: desc }) {\n    id\n    date\n    description\n    image\n    title\n  }\n}\n": types.NewsDocument,
    "mutation updateFestivalSettingsMutation($minVersion:String!, $downForMaintenance: Boolean!, $editionId : String!, $hiddenMenuItems: String! $countdownDate: DateTime) {\n    updateFestivalSettings(minVersion: $minVersion, downForMaintenance: $downForMaintenance, editionId: $editionId, hiddenMenuItems: $hiddenMenuItems, countdownDate: $countdownDate) {\n      id\n    }\n  }": types.UpdateFestivalSettingsMutationDocument,
    "\nquery settings {\n    settings {\n      id\n      minVersion\n      downForMaintenance    \n      editionId\n      hiddenMenuItems\n      countdownDate\n    }\n    versionNumber\n  }": types.SettingsDocument,
    "\nquery showcaseBands {\n    bands(where: { showcase: { equals: true } }) {\n      id\n      name\n      imageUrl\n      description\n      showcase\n    }\n  }\n": types.ShowcaseBandsDocument,
    "mutation exportMiscAppConfig {\n  exportMiscAppConfig\n}": types.ExportMiscAppConfigDocument,
    "mutation removeAllShowcases { \n  removeAllShowcases\n  }": types.RemoveAllShowcasesDocument,
    "\nquery getStageById($id: String!) {\n  stage(where: { id: $id }) {\n    id\n    name\n    order\n  }\n}\n": types.GetStageByIdDocument,
    "\n  mutation createStage($name: String!) {\n    createStage(name: $name) {\n      id\n    }\n  }\n": types.CreateStageDocument,
    "\nmutation updateStage($id: String!, $name:String!, $order:Int!) {\n  updateStage(stage: {id: $id, name: $name, order: $order}) {\n      id\n      name\n      order\n    }\n  }": types.UpdateStageDocument,
    "\nquery getAllStages {\n  stages(orderBy: {order: asc}) {\n    id\n    name\n    order\n  }\n}\n": types.GetAllStagesDocument,
    "\nmutation deleteStage($stageId:String!) {\n  deleteStage(stageId: $stageId) {\n    id\n\n    }\n}\n": types.DeleteStageDocument,
    "\nquery users {\n  users {\n    id\n    email\n    firstName\n    lastName\n    fullName\n    type\n  }\n}\n": types.UsersDocument,
    "\nquery user($id: String!) {\n  users(where: {id: {equals: $id}}) {\n    id\n    email\n    firstName\n    lastName\n    fullName\n    type\n    password\n  }\n}\n": types.UserDocument,
    "\nmutation createUser($email: String!, $firstName: String!, $lastName: String!, $organisationId: String!, $password: String!) {\n  createUser(\n    email: $email\n    firstName: $firstName\n    lastName: $lastName\n    organisationId: $organisationId\n    password: $password\n  ) {\n    id\n  }\n}\n": types.CreateUserDocument,
    "\nmutation updateUser($id:String!, $email: String, $firstName: String, $lastName: String, $password: String) {\n  updateUser(user: {\n    id: $id\n    email: $email\n    firstName: $firstName\n    lastName: $lastName\n    password: $password\n  }\n  ) {\n    id\n  }\n}\n": types.UpdateUserDocument,
    "\nquery me {\n  me {\n    id\n    firstName\n    lastName  \n    userRole: type\n  }\n}\n": types.MeDocument,
    "\nquery foodVendors {\n  foodVendors {\n    id\n    name\n    image\n    description\n    type    \n  }\n}\n": types.FoodVendorsDocument,
    "\nquery foodVendor($id: String!) {\n  foodVendor(where: {\n    id: $id\n  }) {\n    id\n    name\n    image\n    description\n    type\n  }\n}\n": types.FoodVendorDocument,
    "\nquery vendor($id: String!) {\n  foodVendor (where: {id: $id}){\n    id\n    name\n    description\n    image\n    type    \n  }\n}\n": types.VendorDocument,
    "\nmutation updateVendor($vendor: UpdateVendorInputType!) {\n  updateFoodVendor(vendor: $vendor) {\n    id\n    name\n    description\n    image\n    type    \n\n  }\n}\n": types.UpdateVendorDocument,
    "\nmutation createFoodVendor($name: String!, $description:String!) {\n  createFoodVendor(name: $name, description:$description){\n    id\n    name\n    description\n  }\n}\n": types.CreateFoodVendorDocument,
    "mutation deleteFoodVendor($vendorId:String!) {\n  deleteFoodVendor(vendorId: $vendorId){\n    id\n  }\n}": types.DeleteFoodVendorDocument,
};

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 *
 *
 * @example
 * ```ts
 * const query = gql(`query GetUser($id: ID!) { user(id: $id) { name } }`);
 * ```
 *
 * The query argument is unknown!
 * Please regenerate the types.
 */
export function gql(source: string): unknown;

/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery bandsForPicker($orgId: String!) {\n  bands(where: { organisationId: { equals: $orgId } }) {\n    id\n    name\n  }\n}\n"): (typeof documents)["\nquery bandsForPicker($orgId: String!) {\n  bands(where: { organisationId: { equals: $orgId } }) {\n    id\n    name\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery stages {\n  stages {\n    id\n    name\n  }\n}\n"): (typeof documents)["\nquery stages {\n  stages {\n    id\n    name\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery band($id: String!) {\n  band (where: {id: $id}){\n    id\n    name\n    description\n    imageUrl\n    showcase\n    bookings {\n      id\n      edition {\n        id\n        name\n      }\n      startTime\n      endTime\n      stage {\n        name\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery band($id: String!) {\n  band (where: {id: $id}){\n    id\n    name\n    description\n    imageUrl\n    showcase\n    bookings {\n      id\n      edition {\n        id\n        name\n      }\n      startTime\n      endTime\n      stage {\n        name\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateBand($id: String!, $name: String, $description: String, $imageUrl: String, $showcase: Boolean) {\n  updateBand(band: { id: $id, name: $name, description: $description, imageUrl: $imageUrl, showcase: $showcase }) {\n    id\n    name\n    description\n    imageUrl\n    showcase\n  }\n}\n"): (typeof documents)["\nmutation updateBand($id: String!, $name: String, $description: String, $imageUrl: String, $showcase: Boolean) {\n  updateBand(band: { id: $id, name: $name, description: $description, imageUrl: $imageUrl, showcase: $showcase }) {\n    id\n    name\n    description\n    imageUrl\n    showcase\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation ceateBand($name: String!, $description:String!, $organisationId: String!) {\n  createBand(name: $name, description:$description,organisationId: $organisationId){\n    id\n  }\n}\n"): (typeof documents)["\nmutation ceateBand($name: String!, $description:String!, $organisationId: String!) {\n  createBand(name: $name, description:$description,organisationId: $organisationId){\n    id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation addImage($image: String!){\n  addImage(image: $image)\n}\n"): (typeof documents)["\nmutation addImage($image: String!){\n  addImage(image: $image)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation deleteBand($bandId:String!) {\n  deleteBand(bandId: $bandId){\n    id\n  }\n}"): (typeof documents)["mutation deleteBand($bandId:String!) {\n  deleteBand(bandId: $bandId){\n    id\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery bands($take: Int!, $skip: Int!, $where: BandWhereInput) {\n  bandCount\n  bands(skip:$skip, take: $take, where: $where) {\n    id\n    name\n    description\n    bookings {\n      edition {\n        id\n        year\n      }\n      id\n      date\n      stage {\n        name\n      }\n    }\n  }\n}\n"): (typeof documents)["\nquery bands($take: Int!, $skip: Int!, $where: BandWhereInput) {\n  bandCount\n  bands(skip:$skip, take: $take, where: $where) {\n    id\n    name\n    description\n    bookings {\n      edition {\n        id\n        year\n      }\n      id\n      date\n      stage {\n        name\n      }\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery bandBooking($id:String) { \n  bandBooking(where: {id: $id}){\n    id\n    edition {\n      id\n    }\n    band  {\n      id\n      name\n    }\n    stage {\n      id\n      name\n    }\n    date\n    duration\n    startTime\n    endTime  \n  }}\n"): (typeof documents)["\nquery bandBooking($id:String) { \n  bandBooking(where: {id: $id}){\n    id\n    edition {\n      id\n    }\n    band  {\n      id\n      name\n    }\n    stage {\n      id\n      name\n    }\n    date\n    duration\n    startTime\n    endTime  \n  }}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateBooking($id: String!, $startTime: String!, $endTime: String!, $date: String!, $stageId: String!) {\n  updateBandBooking(bandBooking: { id: $id, startTime:$startTime, endTime: $endTime, date: $date, stageId: $stageId})\n  {\n    id\n    band  {\n      id\n      name\n    }\n    date\n    duration\n    startTime\n    endTime \n    stage {\n      name\n    }\n  }\n}\n"): (typeof documents)["\nmutation updateBooking($id: String!, $startTime: String!, $endTime: String!, $date: String!, $stageId: String!) {\n  updateBandBooking(bandBooking: { id: $id, startTime:$startTime, endTime: $endTime, date: $date, stageId: $stageId})\n  {\n    id\n    band  {\n      id\n      name\n    }\n    date\n    duration\n    startTime\n    endTime \n    stage {\n      name\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation deleteBandBooking($bookingId: String!) {\n  deleteBandBooking(bookingId: $bookingId) {\n    id\n  }\n}\n"): (typeof documents)["\nmutation deleteBandBooking($bookingId: String!) {\n  deleteBandBooking(bookingId: $bookingId) {\n    id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation createBooking($bandId: String!, $startTime: String!,$editionId: String!, $stageId: String!, $endTime: String!, $date: String!  ) {\n  createBandBooking(bandId: $bandId,\n                   startTime: $startTime,\n  editionId: $editionId,\n  stageId: $stageId,\n            endTime: $endTime,\n            date: $date) {\n    id\n                   }\n}\n"): (typeof documents)["\nmutation createBooking($bandId: String!, $startTime: String!,$editionId: String!, $stageId: String!, $endTime: String!, $date: String!  ) {\n  createBandBooking(bandId: $bandId,\n                   startTime: $startTime,\n  editionId: $editionId,\n  stageId: $stageId,\n            endTime: $endTime,\n            date: $date) {\n    id\n                   }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery config { \n    configs {\n        keyId: key\n        key\n        value\n  }\n  }"): (typeof documents)["\nquery config { \n    configs {\n        keyId: key\n        key\n        value\n  }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation createConfig($settings: [ConfigInputType]!) { \n    updateConfig(settings: $settings)\n}\n"): (typeof documents)["\nmutation createConfig($settings: [ConfigInputType]!) { \n    updateConfig(settings: $settings)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation deleteConfig($key: String!) { \n    deleteConfig(key: $key) {\n        key\n    }\n}\n"): (typeof documents)["\nmutation deleteConfig($key: String!) { \n    deleteConfig(key: $key) {\n        key\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery drinks {\n  drinks {\n    id\n    drinkId:id\n    name\n    description\n    abv\n    manufacturer\n    type\n  }\n}\n"): (typeof documents)["\nquery drinks {\n  drinks {\n    id\n    drinkId:id\n    name\n    description\n    abv\n    manufacturer\n    type\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateDrinks($drinks: [DrinkInputType!]!) {\n  updateDrinks(drinks: $drinks) {\n    id\n    drinkId:id\n    name\n    description\n    abv\n    manufacturer\n  }\n}"): (typeof documents)["mutation updateDrinks($drinks: [DrinkInputType!]!) {\n  updateDrinks(drinks: $drinks) {\n    id\n    drinkId:id\n    name\n    description\n    abv\n    manufacturer\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation deleteDrink($drinkId: String!) {\n  deleteDrink(drinkId: $drinkId)\n}"): (typeof documents)["mutation deleteDrink($drinkId: String!) {\n  deleteDrink(drinkId: $drinkId)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    mutation updateBookings($bandBookings: [BandBookingUpdateInputType]!) {\n      updateBandBookings(bandBookings: $bandBookings)\n      {\n        id\n        band  {\n          id\n          name\n        }\n        date\n        duration\n        startTime\n        endTime \n        stage {\n          name\n        }\n      }\n    }\n    "): (typeof documents)["\n    mutation updateBookings($bandBookings: [BandBookingUpdateInputType]!) {\n      updateBandBookings(bandBookings: $bandBookings)\n      {\n        id\n        band  {\n          id\n          name\n        }\n        date\n        duration\n        startTime\n        endTime \n        stage {\n          name\n        }\n      }\n    }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n        query festivalEditionForEdit($id: String!) {\n          festivalEdition(where: {\n            id: $id\n          }) {\n            name\n            year\n            bands {\n              id\n              bookingId: id\n              showcase\n              band {\n                id\n                name\n                description\n                imageUrl\n                bookings {\n                  id\n                  startTime\n                  endTime\n                }\n              }\n              date\n              duration\n              stage {\n                id\n                name\n              }\n              startTime\n              endTime\n            }\n            days {\n              id\n              date\n              name\n            }\n          }\n        }\n        "): (typeof documents)["\n        query festivalEditionForEdit($id: String!) {\n          festivalEdition(where: {\n            id: $id\n          }) {\n            name\n            year\n            bands {\n              id\n              bookingId: id\n              showcase\n              band {\n                id\n                name\n                description\n                imageUrl\n                bookings {\n                  id\n                  startTime\n                  endTime\n                }\n              }\n              date\n              duration\n              stage {\n                id\n                name\n              }\n              startTime\n              endTime\n            }\n            days {\n              id\n              date\n              name\n            }\n          }\n        }\n        "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation createEdition($name: String!, $year: String!, $orgId: String!) {\n  createEdition (festival: {\n    name: $name, year: $year, organisationId: $orgId\n  }){\n    id\n    name    \n  }\n}\n"): (typeof documents)["\nmutation createEdition($name: String!, $year: String!, $orgId: String!) {\n  createEdition (festival: {\n    name: $name, year: $year, organisationId: $orgId\n  }){\n    id\n    name    \n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery festivalSingalEdition($festivalId: String!) {\n  festivalEdition(where: {\n    id: $festivalId\n  }) {\n    id\n    name\n    year\n    availabilityDate\n  }\n}\n"): (typeof documents)["\nquery festivalSingalEdition($festivalId: String!) {\n  festivalEdition(where: {\n    id: $festivalId\n  }) {\n    id\n    name\n    year\n    availabilityDate\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateEdition($id:String!, $name: String!, $year:String!, $availabilityDate: String!) {\n  updateEdition(festival: {\n    id: $id,\n    name: $name,\n    year: $year    \n    availabilityDate: $availabilityDate\n}){\n    id\n}\n}\n"): (typeof documents)["\nmutation updateEdition($id:String!, $name: String!, $year:String!, $availabilityDate: String!) {\n  updateEdition(festival: {\n    id: $id,\n    name: $name,\n    year: $year    \n    availabilityDate: $availabilityDate\n}){\n    id\n}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation deleteEdition($editionId: String!) {\n  deleteEditionAndBands(editionId: $editionId)\n}"): (typeof documents)["mutation deleteEdition($editionId: String!) {\n  deleteEditionAndBands(editionId: $editionId)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery festivalEditions {\n    festivalEditions {\n        id\n        name\n      year\n      days {\n        id\n        date\n        name\n      }\n    }\n  }\n"): (typeof documents)["\nquery festivalEditions {\n    festivalEditions {\n        id\n        name\n      year\n      days {\n        id\n        date\n        name\n      }\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery festivalEdition($id: String!) {\n  festivalEdition(where: {\n    id: $id\n  }) {\n    name\n    year\n    bands {\n      id\n      showcase\n      band {\n        id\n        name\n        description\n        imageUrl\n        bookings {\n          id\n          startTime\n          endTime\n        }\n      }\n      date\n      duration\n      stage {\n        name\n      }\n      startTime\n      endTime\n    }\n    days {\n      id\n      date\n      name\n    }\n  }\n}\n"): (typeof documents)["\nquery festivalEdition($id: String!) {\n  festivalEdition(where: {\n    id: $id\n  }) {\n    name\n    year\n    bands {\n      id\n      showcase\n      band {\n        id\n        name\n        description\n        imageUrl\n        bookings {\n          id\n          startTime\n          endTime\n        }\n      }\n      date\n      duration\n      stage {\n        name\n      }\n      startTime\n      endTime\n    }\n    days {\n      id\n      date\n      name\n    }\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query clashfinderExport($name: String!, $id:String!) {\n  clashfinderExport(name: $name, id:$id)\n}"): (typeof documents)["query clashfinderExport($name: String!, $id:String!) {\n  clashfinderExport(name: $name, id:$id)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation addAssets($key: String!){\n  addAssets(key: $key)\n}\n"): (typeof documents)["\nmutation addAssets($key: String!){\n  addAssets(key: $key)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation getExportMetaConfigUrl($key: String!){\n  getExportMetaConfigUrl(key: $key)\n}\n"): (typeof documents)["\nmutation getExportMetaConfigUrl($key: String!){\n  getExportMetaConfigUrl(key: $key)\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery info($id: String!) {\n  info(where: {\n    id: $id\n  }) {\n    id\n    infoId: id\n    title\n    value\n    order\n    hidden\n}\n}\n"): (typeof documents)["\nquery info($id: String!) {\n  info(where: {\n    id: $id\n  }) {\n    id\n    infoId: id\n    title\n    value\n    order\n    hidden\n}\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query infos {\n      infos {\n         id\n         infoId: id\n        title\n        value\n        order\n        hidden\n}\n    }\n    "): (typeof documents)["\n    query infos {\n      infos {\n         id\n         infoId: id\n        title\n        value\n        order\n        hidden\n}\n    }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createInfo($title: String!, $value: JSON!) {\n  createInfo(\n    title: $title,\n    value: $value,\n  ) {\n    id\n    infoId: id\n    title\n    value\n    order\n    hidden\n    }\n}"): (typeof documents)["\n  mutation createInfo($title: String!, $value: JSON!) {\n  createInfo(\n    title: $title,\n    value: $value,\n  ) {\n    id\n    infoId: id\n    title\n    value\n    order\n    hidden\n    }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation updateInfo($id: String!, $title: String!, $value: JSON!, $hidden: Boolean!, $order: Int!) {\n  updateInfo(info:{id: $id, title: $title, value: $value, hidden: $hidden, order:$order}) {\n    id  \n    title\n    value\n    order\n    hidden  \n  }  \n}    "): (typeof documents)["\n  mutation updateInfo($id: String!, $title: String!, $value: JSON!, $hidden: Boolean!, $order: Int!) {\n  updateInfo(info:{id: $id, title: $title, value: $value, hidden: $hidden, order:$order}) {\n    id  \n    title\n    value\n    order\n    hidden  \n  }  \n}    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n mutation deleteInfo($infoId: String!) {\n  deleteInfo(infoId: $infoId) {\n    id\n  }\n}"): (typeof documents)["\n mutation deleteInfo($infoId: String!) {\n  deleteInfo(infoId: $infoId) {\n    id\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery links {\n  links {\n    id\n    linkId:id\n    title\n    url\n    disabled\n    linkType\n    order\n  }\n}\n"): (typeof documents)["\nquery links {\n  links {\n    id\n    linkId:id\n    title\n    url\n    disabled\n    linkType\n    order\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateLink($links: [LinkInputType!]!) {\n    updateLinks(links:$links)\n  }"): (typeof documents)["mutation updateLink($links: [LinkInputType!]!) {\n    updateLinks(links:$links)\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation deleteLink($linkId: String!) {\n  deleteLink(linkId: $linkId) {\n    id\n  }\n}"): (typeof documents)["mutation deleteLink($linkId: String!) {\n  deleteLink(linkId: $linkId) {\n    id\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation signin($email: String!, $password: String!) {\n  signin(email: $email, password: $password) {\n    token\n  }\n}\n"): (typeof documents)["\nmutation signin($email: String!, $password: String!) {\n  signin(email: $email, password: $password) {\n    token\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n    query getAppAndAsetConfigs {\n        appConfigs {\n            id\n            value\n        }\n        assetConfigs {\n            id\n            value\n        }\n    }\n    "): (typeof documents)["\n    query getAppAndAsetConfigs {\n        appConfigs {\n            id\n            value\n        }\n        assetConfigs {\n            id\n            value\n        }\n    }\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery assetConfig($id: String!) {\n  assetConfig(where: { id: $id }) {\n    id\n    value\n  }\n}\n    "): (typeof documents)["\nquery assetConfig($id: String!) {\n  assetConfig(where: { id: $id }) {\n    id\n    value\n  }\n}\n    "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation createAppAndAssetConfig($id: String!) {\n    createAppAndAssetConfig(id: $id)\n}"): (typeof documents)["mutation createAppAndAssetConfig($id: String!) {\n    createAppAndAssetConfig(id: $id)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateAppConfig($id: String!, $value: Json!) {\n    updateAppConfig(id: $id, value: $value){\n      id\n      value\n    }\n}\n"): (typeof documents)["mutation updateAppConfig($id: String!, $value: Json!) {\n    updateAppConfig(id: $id, value: $value){\n      id\n      value\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateAssetConfig($id: String!, $value: Json!) {\n    updateAssetConfig(id: $id, value: $value){\n      id\n      value\n    }\n}\n"): (typeof documents)["mutation updateAssetConfig($id: String!, $value: Json!) {\n    updateAssetConfig(id: $id, value: $value){\n      id\n      value\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery getAppAndAssetConfigs($id: String!) {\n    appConfig(where: { id: $id }) {\n        id\n        value\n      }\n    assetConfig(where: { id: $id }) {\n      id\n      value\n    }\n  }\n"): (typeof documents)["\nquery getAppAndAssetConfigs($id: String!) {\n    appConfig(where: { id: $id }) {\n        id\n        value\n      }\n    assetConfig(where: { id: $id }) {\n      id\n      value\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  query getMetaAssetUrl($key: String!) {\n  getMetaAssetUrl(key: $key)\n}\n      "): (typeof documents)["\n  query getMetaAssetUrl($key: String!) {\n  getMetaAssetUrl(key: $key)\n}\n      "];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation notification($title: String!, $message:String!, $exportNumber: String) {\n    sendNotification(title: $title, message:$message, exportNumber:$exportNumber)\n  }"): (typeof documents)["mutation notification($title: String!, $message:String!, $exportNumber: String) {\n    sendNotification(title: $title, message:$message, exportNumber:$exportNumber)\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "query notificationHistories {\n  notificationHistories(orderBy: {\n    date: desc\n  }) {\n    id\n    date\n    title\n    message\n    exportNumber\n  }\n}"): (typeof documents)["query notificationHistories {\n  notificationHistories(orderBy: {\n    date: desc\n  }) {\n    id\n    date\n    title\n    message\n    exportNumber\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery post($id: String!) {\n  post (where: {id: $id}){\n    id\n    date\n    description\n    image\n    title\n    socials\n  }\n}\n"): (typeof documents)["\nquery post($id: String!) {\n  post (where: {id: $id}){\n    id\n    date\n    description\n    image\n    title\n    socials\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updatePost($id: String!, $title: String!, $description: String!, $date: String!, $image: String!, $socials: JSON) {\n  updatePost(post: { id: $id, title: $title, description: $description, date: $date, image:$image, socials: $socials }) {\n    id\n  }\n}\n"): (typeof documents)["\nmutation updatePost($id: String!, $title: String!, $description: String!, $date: String!, $image: String!, $socials: JSON) {\n  updatePost(post: { id: $id, title: $title, description: $description, date: $date, image:$image, socials: $socials }) {\n    id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation createPost($title: String!, $description: String!,$date: DateTime!, $image: String!, $socials: JSON) {\n  createPost(title: $title, description: $description, date: $date, image: $image, socials: $socials ) {\n    id\n  }\n}\n"): (typeof documents)["\nmutation createPost($title: String!, $description: String!,$date: DateTime!, $image: String!, $socials: JSON) {\n  createPost(title: $title, description: $description, date: $date, image: $image, socials: $socials ) {\n    id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation exportPost($postId: String!) {\n  exportPost(postId: $postId)\n}"): (typeof documents)["mutation exportPost($postId: String!) {\n  exportPost(postId: $postId)\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation deletePost($postId:String!) {\n  deletePost(postId: $postId){\n    id\n  }\n}"): (typeof documents)["mutation deletePost($postId:String!) {\n  deletePost(postId: $postId){\n    id\n  }\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery news($take: Int!, $skip: Int!) {\n  posts(skip: $skip, take: $take, orderBy: { date: desc }) {\n    id\n    date\n    description\n    image\n    title\n  }\n}\n"): (typeof documents)["\nquery news($take: Int!, $skip: Int!) {\n  posts(skip: $skip, take: $take, orderBy: { date: desc }) {\n    id\n    date\n    description\n    image\n    title\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation updateFestivalSettingsMutation($minVersion:String!, $downForMaintenance: Boolean!, $editionId : String!, $hiddenMenuItems: String! $countdownDate: DateTime) {\n    updateFestivalSettings(minVersion: $minVersion, downForMaintenance: $downForMaintenance, editionId: $editionId, hiddenMenuItems: $hiddenMenuItems, countdownDate: $countdownDate) {\n      id\n    }\n  }"): (typeof documents)["mutation updateFestivalSettingsMutation($minVersion:String!, $downForMaintenance: Boolean!, $editionId : String!, $hiddenMenuItems: String! $countdownDate: DateTime) {\n    updateFestivalSettings(minVersion: $minVersion, downForMaintenance: $downForMaintenance, editionId: $editionId, hiddenMenuItems: $hiddenMenuItems, countdownDate: $countdownDate) {\n      id\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery settings {\n    settings {\n      id\n      minVersion\n      downForMaintenance    \n      editionId\n      hiddenMenuItems\n      countdownDate\n    }\n    versionNumber\n  }"): (typeof documents)["\nquery settings {\n    settings {\n      id\n      minVersion\n      downForMaintenance    \n      editionId\n      hiddenMenuItems\n      countdownDate\n    }\n    versionNumber\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery showcaseBands {\n    bands(where: { showcase: { equals: true } }) {\n      id\n      name\n      imageUrl\n      description\n      showcase\n    }\n  }\n"): (typeof documents)["\nquery showcaseBands {\n    bands(where: { showcase: { equals: true } }) {\n      id\n      name\n      imageUrl\n      description\n      showcase\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation exportMiscAppConfig {\n  exportMiscAppConfig\n}"): (typeof documents)["mutation exportMiscAppConfig {\n  exportMiscAppConfig\n}"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation removeAllShowcases { \n  removeAllShowcases\n  }"): (typeof documents)["mutation removeAllShowcases { \n  removeAllShowcases\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery getStageById($id: String!) {\n  stage(where: { id: $id }) {\n    id\n    name\n    order\n  }\n}\n"): (typeof documents)["\nquery getStageById($id: String!) {\n  stage(where: { id: $id }) {\n    id\n    name\n    order\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\n  mutation createStage($name: String!) {\n    createStage(name: $name) {\n      id\n    }\n  }\n"): (typeof documents)["\n  mutation createStage($name: String!) {\n    createStage(name: $name) {\n      id\n    }\n  }\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateStage($id: String!, $name:String!, $order:Int!) {\n  updateStage(stage: {id: $id, name: $name, order: $order}) {\n      id\n      name\n      order\n    }\n  }"): (typeof documents)["\nmutation updateStage($id: String!, $name:String!, $order:Int!) {\n  updateStage(stage: {id: $id, name: $name, order: $order}) {\n      id\n      name\n      order\n    }\n  }"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery getAllStages {\n  stages(orderBy: {order: asc}) {\n    id\n    name\n    order\n  }\n}\n"): (typeof documents)["\nquery getAllStages {\n  stages(orderBy: {order: asc}) {\n    id\n    name\n    order\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation deleteStage($stageId:String!) {\n  deleteStage(stageId: $stageId) {\n    id\n\n    }\n}\n"): (typeof documents)["\nmutation deleteStage($stageId:String!) {\n  deleteStage(stageId: $stageId) {\n    id\n\n    }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery users {\n  users {\n    id\n    email\n    firstName\n    lastName\n    fullName\n    type\n  }\n}\n"): (typeof documents)["\nquery users {\n  users {\n    id\n    email\n    firstName\n    lastName\n    fullName\n    type\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery user($id: String!) {\n  users(where: {id: {equals: $id}}) {\n    id\n    email\n    firstName\n    lastName\n    fullName\n    type\n    password\n  }\n}\n"): (typeof documents)["\nquery user($id: String!) {\n  users(where: {id: {equals: $id}}) {\n    id\n    email\n    firstName\n    lastName\n    fullName\n    type\n    password\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation createUser($email: String!, $firstName: String!, $lastName: String!, $organisationId: String!, $password: String!) {\n  createUser(\n    email: $email\n    firstName: $firstName\n    lastName: $lastName\n    organisationId: $organisationId\n    password: $password\n  ) {\n    id\n  }\n}\n"): (typeof documents)["\nmutation createUser($email: String!, $firstName: String!, $lastName: String!, $organisationId: String!, $password: String!) {\n  createUser(\n    email: $email\n    firstName: $firstName\n    lastName: $lastName\n    organisationId: $organisationId\n    password: $password\n  ) {\n    id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateUser($id:String!, $email: String, $firstName: String, $lastName: String, $password: String) {\n  updateUser(user: {\n    id: $id\n    email: $email\n    firstName: $firstName\n    lastName: $lastName\n    password: $password\n  }\n  ) {\n    id\n  }\n}\n"): (typeof documents)["\nmutation updateUser($id:String!, $email: String, $firstName: String, $lastName: String, $password: String) {\n  updateUser(user: {\n    id: $id\n    email: $email\n    firstName: $firstName\n    lastName: $lastName\n    password: $password\n  }\n  ) {\n    id\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery me {\n  me {\n    id\n    firstName\n    lastName  \n    userRole: type\n  }\n}\n"): (typeof documents)["\nquery me {\n  me {\n    id\n    firstName\n    lastName  \n    userRole: type\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery foodVendors {\n  foodVendors {\n    id\n    name\n    image\n    description\n    type    \n  }\n}\n"): (typeof documents)["\nquery foodVendors {\n  foodVendors {\n    id\n    name\n    image\n    description\n    type    \n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery foodVendor($id: String!) {\n  foodVendor(where: {\n    id: $id\n  }) {\n    id\n    name\n    image\n    description\n    type\n  }\n}\n"): (typeof documents)["\nquery foodVendor($id: String!) {\n  foodVendor(where: {\n    id: $id\n  }) {\n    id\n    name\n    image\n    description\n    type\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nquery vendor($id: String!) {\n  foodVendor (where: {id: $id}){\n    id\n    name\n    description\n    image\n    type    \n  }\n}\n"): (typeof documents)["\nquery vendor($id: String!) {\n  foodVendor (where: {id: $id}){\n    id\n    name\n    description\n    image\n    type    \n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation updateVendor($vendor: UpdateVendorInputType!) {\n  updateFoodVendor(vendor: $vendor) {\n    id\n    name\n    description\n    image\n    type    \n\n  }\n}\n"): (typeof documents)["\nmutation updateVendor($vendor: UpdateVendorInputType!) {\n  updateFoodVendor(vendor: $vendor) {\n    id\n    name\n    description\n    image\n    type    \n\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "\nmutation createFoodVendor($name: String!, $description:String!) {\n  createFoodVendor(name: $name, description:$description){\n    id\n    name\n    description\n  }\n}\n"): (typeof documents)["\nmutation createFoodVendor($name: String!, $description:String!) {\n  createFoodVendor(name: $name, description:$description){\n    id\n    name\n    description\n  }\n}\n"];
/**
 * The gql function is used to parse GraphQL queries into a document that can be used by GraphQL clients.
 */
export function gql(source: "mutation deleteFoodVendor($vendorId:String!) {\n  deleteFoodVendor(vendorId: $vendorId){\n    id\n  }\n}"): (typeof documents)["mutation deleteFoodVendor($vendorId:String!) {\n  deleteFoodVendor(vendorId: $vendorId){\n    id\n  }\n}"];

export function gql(source: string) {
  return (documents as any)[source] ?? {};
}

export type DocumentType<TDocumentNode extends DocumentNode<any, any>> = TDocumentNode extends DocumentNode<  infer TType,  any>  ? TType  : never;