import { gql } from "../../../__generated__/gql";

export const GET_LINKS = gql(`
query links {
  links {
    id
    linkId:id
    title
    url
    disabled
    linkType
    order
  }
}
`);

export const UPDATE_LINKS =
  gql(`mutation updateLink($links: [LinkInputType!]!) {
    updateLinks(links:$links)
  }`);

export const DELETE_LINK = gql(`mutation deleteLink($linkId: String!) {
  deleteLink(linkId: $linkId) {
    id
  }
}`);
